import { RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages.component';
import { AuthGuard } from '../guards/auth.guard';
import { VerificaTokenGuard } from '../guards/verifica-token.guard';
import { DhasboardComponent } from './dhasboard/dhasboard.component';
import { AdminGuard } from '../guards/admin.guard';
import { DireccionGuard } from '../guards/direccion.guard';
import { ClientesComponent } from './clientes/clientes.component';
import { ComercialGuard } from '../guards/comercial.guard';
import { ClienteComponent } from './cliente/cliente.component';
import { AccoutSettingsComponent } from './accout-settings/accout-settings.component';

import { PedidosComponent } from './pedidos/pedidos.component';
import { ReportesComponent } from './reportes/reportes.component';
import { PersonalComponent } from './personal/personal.component';
import { UnidadesComponent } from './unidades/unidades.component';
import { CotizacionesClienteComponent } from './cotizaciones-cliente/cotizaciones-cliente.component';
import { TipoUnidadComponent } from './tipo-unidad/tipo-unidad.component';
import { CotizadorComponent } from './cotizador/cotizador.component';
import { CotizadorOperadorComponent } from './cotizador-operador/cotizador-operador.component';
import { CotizacionesOperadorComponent } from './cotizaciones-operador/cotizaciones-operador.component';


const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        canActivate: [ AuthGuard, VerificaTokenGuard ],
        children: [
            { path: 'dhasboard',
              component: DhasboardComponent,
              data: { titulo: 'Dashboard' },
              canActivate: [ AuthGuard, VerificaTokenGuard ]
            },

             { path: 'clientes',
               component: ClientesComponent,
               data: { titulo: 'Clientes' },
              // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
             },
             { path: 'cliente/:id',
               component: ClienteComponent,
               data: {titulo: 'Clientes' },
              // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard ]
             },

           { path: 'personal',
             component:   PersonalComponent,
             data: { titulo: 'Personal' },
             // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
            },

            { path: 'pedidos',
             component: PedidosComponent,
             data: { titulo: 'Pedidos' },
             // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
            },
            { path: 'unidades',
             component: UnidadesComponent,
             data: { titulo: 'Unidades' },
             // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
            },
            { path: 'tipoUnidad',
             component: TipoUnidadComponent,
             data: { titulo: 'tipoUnidad' },
             // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
            },

            { path: 'cotizador',
               component: CotizacionesClienteComponent,
               data: { titulo: 'Cotizador' },
              // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
             },

             { path: 'cotizacion/:id',
               component: CotizadorComponent,
               data: {titulo: 'Cotizacion' },
              // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard ]
             },

             { path: 'cotizadorClientes',
               component: CotizacionesOperadorComponent,
               data: { titulo: 'CotizadorClientes' },
              // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
             },

             { path: 'cotizadorOper/:id',
               component: CotizadorOperadorComponent,
               data: {titulo: 'cotizadorOper' },
              // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard ]
             },

            { path: 'reportes',
             component: ReportesComponent,
             data: { titulo: 'Reportes' },
             // canActivate: [ AuthGuard, VerificaTokenGuard, ComercialGuard]
            },
         
        
           { path: 'accout-settings',
               component: AccoutSettingsComponent,
               data: { titulo: 'Ajustes' },
               canActivate: [ AuthGuard, VerificaTokenGuard ]
            },
            { path: '',
               redirectTo: '/dhasboard',
               pathMatch: 'full'
            }
            ]
    }
];


export const PAGES_ROUTES = RouterModule.forChild( pagesRoutes );
