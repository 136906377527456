import { Component, OnInit } from '@angular/core';
import { CotizacionesService } from 'src/app/services/cotizaciones/cotizaciones.service';
import { CotizacionModel } from '../../models/cotizacion.model';

@Component({
  selector: 'app-cotizaciones-cliente',
  templateUrl: './cotizaciones-cliente.component.html',
  styleUrls: ['./cotizaciones-cliente.component.css']
})
export class CotizacionesClienteComponent implements OnInit {

  cotizacion: CotizacionModel[] = [];//unidad
  cotizacionID = new CotizacionModel();
  cargando = false;
  result = false;
  config: any;
  checkFactura: string;
  texto = ['Cotizaciones Registradas', 'Cotizaciones Confirmadas', 'Cotizaciones Pendientes'];
  icon = ['fas fa-file-pdf-o', 'fas fa-file-pdf-o', 'fas fa-file-pdf-o'];
  numeros: number[] = [0, 0, 0];
  constructor(private cotizacionesService: CotizacionesService) { }

  ngOnInit() {
    this.numeros = [0, 0, 0];
    this.checkFactura = '';
    this.config = {
      itemsPerPage: 10,
      currentPage: 1
    };

    this.cargando = true;
    this.cargarCotizaciones();
   // this.cargarProductoEstadistico();
   // this.cargarProductoAsignado();
  }

  buscarCotizacion(termino: string){

    return ;

  }

  cargarCotizaciones() {
    this.cotizacionesService.getCotizacion(-1)
    .subscribe( (resp: any) => {
      if (resp.length>0) {
         this.cotizacion= resp;
         this.numeros = [this.cotizacion.length, 3, 0];
      } else {
        this.result = true;
        this.cargando = false;

      }
      this.cargando = false;
    });
  }

  

  pageChanged(event) {
    this.config.currentPage = event;
  }

 

  cargarProductoAsignado() {
    /*this.productoService.getProductoAsignado(this.token, localStorage.getItem('cveEmp'))
      .subscribe((resp: any) => {

        if (resp['0'].claveProducto != null) {
          this.producto = resp;
        } else {
          this.result = true;
        }
        this.cargando = false;
      });*/
  }

  cargarProductoEstadistico() {
   /* this.productoService.getProductoEstadistico(this.token, localStorage.getItem('cveEmp'))
      .subscribe((resp: any) => {
        if (resp['0'] != null) {
          const { Total, Asignado, Disponible } = resp['0'];
          this.numeros = [Total, Disponible, Asignado];
        } else {
          this.result = true;
        }
        this.cargando = false;
      });*/
  }

  buscarProducto(termino: string) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null) {
      this.cargando = false;
      this.cargarProductoAsignado();
      return;
    }
    /*this.productoService.getProductoSearch(this.token, termino)
      .subscribe((resp: any) => {
        if (resp.length < 1) {
          this.cargando = false;
          this.result = true;
          this.producto = [];
          return;
        } else {
          if (resp['0'] != null) {
            this.producto = resp;
            this.cargando = false;
            this.result = false;
          }
        }
        return this.producto;
      });*/
  }

  buscarEquipo(termino: string) {
    this.cargando = true;
    if (termino.length <= 0 || termino === null) {
      this.cargando = false;
      this.cargarProductoAsignado();
      return;
    }
    /*this.productoService.getProductoSearchIdentificador(this.token, termino)
      .subscribe((resp: any) => {
        if (resp['0'] === null) {
          this.cargando = false;
          this.result = true;
          this.producto = [];
          return;
        } else {
          if (resp['0'] != null) {
            this.producto = resp;
            this.cargando = false;
            this.result = false;
          } else {
            this.cargando = false;
            this.result = true;
            this.producto = [];
            return;
          }
        }
        return (this.producto);
      });*/
  }

  detalleInfo(id: string) {
    if (id !== 'nuevo') {
     /* this.productoService.getProductoID(this.token, id)
        .subscribe(resp => {
          this.productoID = resp['0'];
        });*/
    }
  }

}